import quantities from './quantities.json';

export default class Quantities {
    constructor () {
        this.quantities = quantities;
    }
    handle () {
        var $elements = $('input.pro_qty');

        $elements.each(function () {
            let $el = $(this)
            let step = 1;
            let productId = $el.closest('.product').data('id');

            for (let stepValue of Object.keys(quantities)) {
                let products = quantities[stepValue];
                if (products.indexOf(productId) !== -1) {
                    step = stepValue
                    $el.parent().append("<small>Unité d'emballage: " + stepValue + "</small>");
                }
            }

            $el.attr('min', 0);
            $el.attr('step', step);
            $el.inputSpinner({
                decrementButton: "<strong>&minus;</strong>", // button text
                incrementButton: "<strong>&plus;</strong>", // ..
                groupClass: "", // css class of the resulting input-group
                buttonsClass: "btn-default",
                buttonsWidth: "2.5rem",
                textAlign: "center", // alignment of the entered number
                autoDelay: 500, // ms threshold before auto value change
                autoInterval: 50, // speed of auto value change
                buttonsOnly: false, // set this `true` to disable the possibility to enter or paste the number via keyboard
                template: // the template of the input
                    '<div class="input-group ${groupClass}">' +
                    '<span class="input-group-btn"><button style="min-width: ${buttonsWidth}" class="btn btn-decrement ${buttonsClass} btn-minus" type="button">${decrementButton}</button></span>' +
                    '<input type="text" inputmode="decimal" style="text-align: ${textAlign}" class="form-control form-control-text-input"/>' +
                    '<span class="input-group-btn"><button style="min-width: ${buttonsWidth}" class="btn btn-increment ${buttonsClass} btn-plus" type="button">${incrementButton}</button></span>' +
                    '</div>'
            });

            $el.on('change', function (e) {
                let value = parseInt($(this).val());
                let step = parseInt($(this).attr('step'));
                let dividable = value % step === 0;
                if (value !== NaN && !dividable) {
                    value -= value % step
                    $(this).val(value);
                }
            })
        })


    }
}
