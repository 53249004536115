export default function submitForm() {
        var success = validateForm();
        var qtysum = getQty();

        if (qtysum <= 0) {
            $('#order_error_message').html(errorQty);
            success = false;
        } else {
            $('#order_error_message').html('');
        }

        if (success === true) {
            var values = JSON.stringify($("#orderForm").serializeArray());

            $.ajax({
                type: "POST",
                cache: false,
                url: "https://www.kundenmeister.com/crm/index.php/kundenmeister_api/submitOrderForm",
                data: values,
                crossDomain: true,
                success: function (data) {
                    if (data === "invalid") {
                        $('#order').html(invalidCaptcha);
                    } else {
                        $('#order').html(successView);
                    }
                }, error: function (xhr, ajaxOptions, thrownError) {
                    $('#order').html('<div style="color:red;margin-top: 20px;"> Beim Absenden der Bestellung gab es leider ein Problem mit den Sicherheitseinstellngen Ihrer aktuellen Browserversion. Sollten Sie eine Version des Microsoft Internet Explorers verwenden, bitte wir Sie den Vorgang mit einem anderen Browser durchzuf&uuml;hren!</div>');
                }
            });
        } else {
            return false;
        }
    }
