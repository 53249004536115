jQuery(document).ready(function($) {
    //clearing the input field if 0
    $('.product_basket input').on('click', function() {
        let value = $(this).val();

        if (value === '0') {
            $(this).val('');
        }
    });

    $('.product_state').remove();
    //remove default Shop-Bootstrap, because it hurts the Layout

    //unbind previous change event handler
    $('.pro_qty').unbind('change');

    //remove old Button, and add new one
   // $('.order_submit').html('<div class="span12"><div class="button" onclick="submitFormValidate()" type="submit">Zahlungspflichtig bestellen</div></div>');

    //bind new event handler
    $('.pro_qty').change(function() {
        if($(this).val() != parseInt($(this).val())) {
            $(this).val('0');
        }

        calculateSum();
    });

    //add delivery costs
    // $('.sum').before('<div class="row-fluid"><div class="span12 deliverycosts">Zustellkosten: <span class="deliveryValue">0.00 &euro;</span></div></div>');

    $('#land').change(function() {
        calculateSum();
    });

    calculateSum();
});

function calculateSum() {
    var deliverycosts = 0; //getDeliveryCosts();
    var sum = getSum();

    //soll getrennt gespeichert werden
    $('#input_sum').val(sum.toFixed(2));

    //Add delivery costs
    sum = sum + deliverycosts;
    $('#order_sum').html(sum.toFixed(2));
    $('.deliveryValue').html(deliverycosts.toFixed(2) + ' &euro;');
}

function getDeliveryCosts() {
    var cost = 0;

    //get sum of quantites of all products
    $('.pro_qty').each(function() {
        let price = $(this).closest('.product').find('.pro_price').val();
        if(parseInt($(this).val()) > 0 && parseFloat(price) > 0) {
            cost += parseInt($(this).val()) * parseFloat(price);
        }
    });
    
    let deliveryCost = 5;

    if (cost > 25 || cost == 0) {
        deliveryCost = 0;
    }

    $('#shipping_total').val(deliveryCost);

    return deliveryCost;
}
