import jQuery from 'jquery';
import GLightbox from 'glightbox';
import Quantities from './quantities'
import Translations from './translations'
import submitForm from './form/submitForm';

global.$ = jQuery
global.jQuery = jQuery
require('bootstrap-input-spinner')

// - Load legacy script
require('./form/orderscript.js')

const translations = new Translations;
const quantities = new Quantities;

$(function () {
    //- Translate page
    translations.translate()

    //- Setup spinner
    quantities.handle();

    //- Set error messages
    global.errorMsg = 'Veuillez remplir ce champ';
    global.errorQty = 'Veuillez saisir le nombre de produits souhaités';

    //- Set success view
    global.successView = '<h2>Nous vous remercions de votre commande!</h2><br>Vous recevrez sous peu un e-mail avec la confirmation de commande.'

    //- Translate dynamicaly generated cart page
    const updateCartOriginal = window.updateCart;
    window.updateCart = function () {
        updateCartOriginal();
        translations.translate()
    }

    //- Hardcode country
    var $countryField = $('input[name="kunde[land]"]');
    var $countryPlaceholder = $('<input type="text">').val('France').attr('readonly', true);
    $countryField.val(56).attr('readonly', true);
    $countryPlaceholder.insertBefore($countryField)
    $countryField.hide();

    //- Add text before prices
    $('.product_options .product_price').prepend('Prix (TTC): ')

    //- Setup lightbox
    const lightbox = GLightbox({
        selector: '.product_image a'
    });

    //- replace submitFormFunctionality
    global.submitForm = submitForm
});

