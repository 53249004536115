import defaultTranslations from './translations.json'

export default class Translations {
    constructor(translations = []) {
        this.translations = defaultTranslations.concat(translations);
    }

    translate() {
        for (const translation of this.translations) {
            if (translation.selector) {
                const nodes = $(translation.selector);
                if (nodes && nodes.length > 1) {
                    nodes.contents().filter(function(){ return this.nodeType == 3; }).each((i, node) => {
                        node.textContent = node.textContent.replace(
                            translation.value,
                            translation.translation,
                        )
                    })
                } else {
                    nodes.text(
                        nodes.text().replace(
                            translation.value,
                            translation.translation,
                        )
                    )
                }
            }
        }
    }
}
